import {
  Divider,
  Grid,
  InternalLink,
  Spacer,
  Text,
  Title,
  useDialogState,
  useToast,
} from '@color/continuum';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import {
  GEM_INFORMING_LOOP_PATH,
  HDR_INFORMING_LOOP_PATH,
  PGX_INFORMING_LOOP_PATH,
} from 'components/App/AppRoutes/constants';
import { useCreateResultDecisionMutation } from 'lib/analytics/result-decisions';
import { ResultDecision, ResultDecisionValue } from 'lib/analytics/result-decisions/types';
import { ModuleType } from 'lib/analytics/types';

import { useOptionsSectionContent } from './content';
import { OptionsToggleSwitch } from './OptionsToggleSwitch';
import { ReviewInformingLoopDialog } from './ReviewInformingLoopDialog';

const INFORMING_LOOP_PATHS: Record<ModuleType, string> = {
  [ModuleType.HDR]: HDR_INFORMING_LOOP_PATH,
  [ModuleType.PGX]: PGX_INFORMING_LOOP_PATH,
  [ModuleType.GEM]: GEM_INFORMING_LOOP_PATH,
};
interface Props {
  moduleType: ModuleType;
  resultDecision?: ResultDecision;
}

export const OptionsSection: React.FC<Props> = (props) => {
  const location = useLocation();
  const { showToast } = useToast();
  const { moduleType, resultDecision } = props;

  const {
    dialogIsOpen: reviewInformingLoopDialogIsOpen,
    openDialog: openReviewInformingLoopDialog,
    closeDialog: closeReviewInformingLoopDialog,
  } = useDialogState();

  const { mutateAsync: saveResultDecision } = useCreateResultDecisionMutation();

  const content = useOptionsSectionContent(moduleType);
  if (!content) {
    return null;
  }
  const { title, description, promptForToggle, successToastMessage } = content;
  return (
    <>
      <Spacer variant="xLarge" />
      <Title variant="headline3" component="h2">
        {title}
      </Title>
      <Spacer variant="small" />
      <Text>
        {description}{' '}
        <InternalLink
          to={{
            state: { backgroundLocation: location },
            pathname: INFORMING_LOOP_PATHS[moduleType],
          }}
        >
          <FormattedMessage
            defaultMessage="Review benefits and risks."
            description="Text for link to review informing loop in options section"
          />
        </InternalLink>
      </Text>
      <ReviewInformingLoopDialog
        dialogIsOpen={reviewInformingLoopDialogIsOpen}
        onClose={() => closeReviewInformingLoopDialog()}
        pathToInformingLoop={INFORMING_LOOP_PATHS[moduleType]}
      />
      <Spacer variant="xLarge" />
      <Grid container alignItems="center" justifyContent="space-between" spacing={1} wrap="nowrap">
        <Grid item>
          <Text bold id="togglePromptLabel">
            {promptForToggle}
          </Text>
        </Grid>
        <Grid item>
          <OptionsToggleSwitch
            checked={resultDecision?.resultVisible}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.checked) {
                openReviewInformingLoopDialog();
              } else {
                saveResultDecision(
                  {
                    moduleType,
                    decisionValue: ResultDecisionValue.HIDE,
                  },
                  {
                    onSuccess: () => {
                      showToast(successToastMessage, { severity: 'success', variant: 'standard' });
                    },
                  }
                );
              }
            }}
          />
        </Grid>
      </Grid>
      <Spacer variant="xLarge" />
      <Divider aria-hidden="true" />
    </>
  );
};
